
@font-face {
    font-family: 'IranSans';
    src: url('./IranSans/IRANSansWeb(FaNum).eot');
    src: url('./IranSans/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
        url('./IranSans/IRANSansWeb(FaNum).woff2') format('woff2'),
        url('./IranSans/IRANSansWeb(FaNum).woff') format('woff'),
        url('./IranSans/IRANSansWeb(FaNum).ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
